.payment_form{
    display: flex;
    width: 100%;
    justify-content: center;
}
.payment_form_content{
    display: flex;
    width: 100%;
    max-width: 728px;
    margin-top: 50px;
    flex-direction: column;
}
label.container{
    margin: 0 ;
}
.payment_bank_logo{
    display: flex;
    width: 100%;
    height: 130px;
}
.payment_bank_logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.payment_form_container{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.payment_form_container{
    margin-top: 80px;
}
.payment_form_title{
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 35px;
    color: black;
}
.payment_form_section{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    text-transform: capitalize;
}
.payment_form_fields{
    display: flex;
    width: 50%;
}
.payment_form_fields .MuiFormControl-root{
    width: 100%;
}
.payment_form_fields form{
    width: 100%;
}
.payment_textarea{
    width: 100%;
    margin-top: 25px;
}
.payment_textarea textarea{
    width: 100%;
    background-color: transparent;
    border: 2px solid black;
    padding: 7px;
}
.right_field{
    padding-left: 20px;
}
.left_field{
    padding-right: 20px;
}
.payment_form_fields .MuiInput-underline:before{
    border-bottom: 2px solid black;
}
.payment_form_fields .MuiFormControl-root{
    width: 100%;
}
.payment_form_fields .MuiFormLabel-root.Mui-focused{
    color: black;
    font-weight: 400;
    font-size: 18px;
}
.payment_form_fields .MuiInput-underline:after{
    border-bottom: 2px solid black;
}
.payment_form_fields .MuiInputLabel-animated{
    color: black;
    font-weight: 400;
    font-size: 18px;
}
.payment_form_fields .MuiInputBase-input{
    padding-bottom: 20px;
}
.payment_button_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 60px;
}
.payment_check_container{
    display: flex;
    width: 100%;
    padding-top: 10px;
    justify-content: flex-start;
    align-items: center;
}

.payment_check_label{
    text-transform: none;
    white-space: nowrap;
    font-weight: 400;
    font-size: 15px;
    color: #5a5a5a;
    padding-top: 2px;
}
.payment_checkbox_container .checkmark.error {
        border-color: red;
}
.payment_check_label > .term.term:hover,
.payment_check_label > .term.term {
    white-space: break-spaces;
    color: black;
    text-decoration: underline;
    cursor: pointer;
}
.payment_form_fields  p{
    margin-bottom: 0;
}
.payment_form_fields  textarea:focus{
    outline: none;
}
.payment_checkbox_container .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.payment_checkbox_container .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment_checkbox_container .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid black;
}
.payment_checkbox_container .container:hover input ~ .checkmark {
    background-color: white;
}
.container input:checked ~ .checkmark {
    background-color: white;
}
.payment_checkbox_container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.payment_checkbox_container .container input:checked ~ .checkmark:after {
    display: block;
}
.payment_checkbox_container .container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 9px;
    border: solid #0078ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.payment_form_fields{
    display: flex;
    flex-direction: column;
}
.payment_form_fields{
    font-size: 18px;
    font-weight: 600;

}
.secure_text{
    font-weight: 400;
    font-size: 15px;
    color: #363636;
}
.secure_label{
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 80px;
}
.secure_icon{
    margin-right: 15px;
}
.donate_button{
    display: flex;
    width: 250px;
    height: 55px;
    border: 5px solid #051a2e;
    color: #051a2e;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.5s;
}
.donate_button:hover{
    color: white;
    background-color: #051a2e;
}
.payment_button_container .page_button{
    height: 55px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
}
@media only screen and (max-width: 991px) {
    .payment_form_content{
        margin-top: 30px;
    }
    .payment_bank_logo{
        width: 100%;
        height: unset;
        padding: 0 20px;
    }
    .payment_bank_logo img{
        height: unset;
        width: 100%;
        object-fit: contain;
    }
    .payment_form_container{
        margin-top: 50px;
        padding: 0 20px;
    }
    .payment_form_title{
        font-size: 30px;
    }
    .payment_form_section{
        flex-direction: column;
    }
    .payment_form_fields{
        padding: 0;
        width: 100%;
    }
    .payment_button_container{
        flex-direction: column;
    }
    .payment_button_container .page_button{
        width: 100%;
        text-align: center
    }
    .secure_label{
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .donate_button{
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 425px) {
    .payment_bank_logo{
        height: 100px;
    }

}


@media only screen and (max-width: 768px) {
    .payment_check_label{
        display: flex;
        flex-direction: column;
    }payment_checkbox_container
    .payment_check_container:not(:first-of-type){
        padding-top:30px;
    }
.payment_checkbox_container .container {
    padding-left: 27px;
}
}