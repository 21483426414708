.wishlis_item_button {
    display: flex;
    width: 160px;
    height: 55px;
    justify-content: center;
    align-items: center;
    color: #0078ff;
    border: 5px solid #0078ff;
    text-transform: uppercase;
    cursor: pointer;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
    min-width: 160px;
    text-transform: uppercase;
    transition: 0.5s;
    text-align: center;
}
.wishlis_item_button:hover {
    background-color: #0078ff;
    color: white;
}
.wishlist_item_cart_button_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}
.forms-carts {
    display: flex;
}

.wishlist_item_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    .wishlist-inp {
        margin-bottom: 20px;
        height: 40px;
    }
    .wishlis_item_button {
        width: 160px;
        height: 40px;
        font-size: 11px;
    }
}
@media only screen and (max-width: 425px) {
    .wishlist-inp {
        max-width: 100px;
    }
}
