@import "../../../styles/";

.shop_page_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.shop_page_wrpper {
    max-width: 1550px;
    padding: 0 20px;
    margin: 0 auto;
    @media screen and (max-width: 1550px) {
        margin: unset;
    }
    @include phone {
        padding: 0 10px;
    }
}
