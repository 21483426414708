@import "../../styles/";
.navigation_bar {
    display: flex;
    width: 100%;
    height: 85px;
    background-color: white;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: space-between;
    z-index: 200;
    .navbar_cnt {
        padding: 0 20px;
        max-width: 1550px;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include phone {
            padding: 0 15px 0 10px;
        }
        .search_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            > svg path {
                fill: black;
                cursor: pointer;
            }
        }
        ul {
            display: flex;
            margin: 0;
            align-items: center;
            @include ipad {
                display: none;
            }
            li {
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 33px;
                }
                > .nav_link {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: bold;
                    text-align: center;
                    color: black;
                }
            }
        }
        .nav_last_child {
            display: flex;
            justify-content: center;
            align-items: center;
            @include ipad {
                margin-right: 50px;
            }
            .nav_lang_container {
                display: flex;
                cursor: pointer;
                width: 100%;
                margin-right: 40px;
                @include phone {
                    display: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 30px;
                    width: 1px;
                    background: #eaeaea;
                    right: -20px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
                position: relative;
            }
            .shop_header_icons_container {
                display: flex;
                justify-content: center;
                align-items: center;
                > div {
                    &:first-child {
                        display: flex;
                        position: relative;
                        align-items: center;
                    }
                }
                .black_heart_container {
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include phone {
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    ellipse {
                        fill: #0078ff;
                    }
                     > svg { 
                         width: 18px;
                         height: 20px;
                     }
                }
                .search_icon {
                    display: none;

                    > svg {
                        height: 15px;
                        width: 15px;
                    }
                    @include ipad {
                        display: flex;
                        margin-left: 20px;
                        border-left: 1px solid #eaeaea;
                        padding-left: 20px;
                        height: 30px;
                    }
                    @include phone {
                        margin-left: 10px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

.nav_logo {
    width: 174px;
    height: 35px;
    background-image: url("../../assets/Logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    @include phone {
        width: 105px;
        height: 25px;
    }
}
.nav_logo img {
    width: 40px;
    height: 48px;
    object-fit: contain;
}
.hamburger_menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    display: none;
    @include ipad {
        display: block;
    }
    > div {
        @include phone {
            width: 25px !important;
        }
        > span {
            min-height: 2.5px;
            max-height: 2.5px;
        }
    }
}
.nav_content {
    display: flex;
    width: 627px;
    background-color: white;
    height: 100vh;
    position: fixed;
    left: -100%;
    transition: all 1s ease-in-out;
    padding-left: 80px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    display: none;
    > .mobile {
        display: none;
        @media screen and (max-width: 767.9px) {
            display: flex;
            margin-bottom: 35px;
        }
    }
}
.nav_content.nav_active {
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    padding: 0 20px;
    display: none;
    overflow: auto;
    z-index: 556;
    @include ipad {
        display: flex;
    }
    @include phone {
        padding: 0 10px;
    }
}
.nav_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 55px;
    padding-right: 54px;
    height: fit-content;
    @include phone {
        margin-top: 40px;
    }
    > .nav_lang_container {
        display: none;
        @include phone {
            display: flex;
        }
    }
}

.nav_header_logo img {
    width: 120px;
}

.nav_pages_list_container {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    background-color: white;
}
.nav_phone_number {
    background-color: #0078ff;
    padding: 16px 0;
    max-width: 450px;
    width: 100%;
    height: 50px;
    display: none;
    bottom: 0;
    position: sticky;
    bottom: 20px;
    justify-content: center;
    transition: 0.4s;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    @include phone {
        height: 40px;
        max-width: unset;
    }
    margin-top: auto;
    border: solid 1px #0078ff;
    &:hover {
        background-color: white;
        > span {
            color: #0078ff;
        }
    }
    @include phone {
        padding: 13px 0;
        min-height: 40px;
    }
    > span {
        color: white;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        height: fit-content;
        text-decoration: unset;
        text-align: center;
        text-transform: uppercase;
    }
}

.nav_lang_item {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-decoration: unset;
    color: black;
    opacity: 0.5;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &:not(:last-child) {
        margin-right: 10px;
    }
    &.active-bold {
        opacity: 1 !important;
    }
}

.nav_menu {
    list-style-type: none;
    list-style-position: inside;
    margin-left: 40px;

    > li {
        cursor: pointer;
        position: relative;
        > .link_popup {
            background: white;
            position: absolute;
            display: none;
            padding: 8px 0;
            width: calc(100% + 10px);
            box-shadow: 0px 3px 15px rgba(3, 23, 47, 0.1);
            top: 100%;
            > a {
                padding: 0 20px;
                font-size: 12px;
                padding-bottom: 5px;
                line-height: 18px;
            }
        }
        &:hover {
            > .link_popup {
                display: block;
                > a {
                    &:hover {
                        color: #0078ff;
                    }
                }
            }
        }
    }
}
.nav_menu li {
    margin-bottom: 13px;
    transition: 0.7s;
}
.nav_menu li:not(.nav_service_pages) {
    margin-bottom: 30px;
}

.home {
    .navigation_bar {
        background-color: #0000004f;
        backdrop-filter: blur(6px);
        border-bottom: unset;
        .nav_logo {
            background-image: url("../../icons/white_logo.svg") !important;
            background-repeat: no-repeat;
        }
        .hamburger_menu {
            span {
                background-color: white !important;
            }
        }
        li > .nav_link {
            color: #ffffff !important;
        }
        svg path {
            fill: #ffffff !important;
        }
        div {
            color: #ffffff;
        }
        .nav_lang_item.active-bold {
            color: #ffffff;
            opacity: 1 !important;
        }
        &.active {
            .nav_lang_item {
                color: black !important;
            }
            .navbar_cnt {
                background-color: white;
                color: black;
                .search_icon {
                    border-color: #eaeaea;
                }
                .nav_logo {
                    background-image: url("../../assets/Logo.svg") !important;
                }
                span {
                    background-color: black !important;
                }
                svg path {
                    fill: black !important;
                }
            }
        }
        &.bg {
            background: #ffffff !important;
            box-shadow: 0px 3px 15px rgba(3, 23, 47, 0.1);
            .nav_logo {
                background-image: url("../../assets/Logo.svg") !important;
            }
            li > .nav_link {
                color: #000000 !important;
            }
            svg path {
                fill: #000000 !important;
            }
            div {
                color: #000000 !important;
            }
            .nav_lang_item.active-bold {
                color: #000000 !important;
                opacity: 1 !important;
            }
            .hamburger_menu {
                span {
                    background-color: black !important;
                }
            }
        }
    }
}

.navigation_bar {
    .nav_lang_item.active-bold {
        color: #000000;
        opacity: 1 !important;
    }
}
.link_popup {
    > a {
        text-transform: capitalize !important;
        font-size: 12px !important;
        line-height: 25px;
        font-weight: 600;
        color: #636363 !important ;
        transition: 0.4s;
        &:hover {
            color: #0078ff !important;
        }
    }
}
.nav_menu {
    li {
        .nav_link,
        a {
            display: flex;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            text-decoration: none;
            color: black;
            &:not([href]):not([tabindex]) {
                color: black;
            }
        }
    }
}
.nav_menu {
    @include phone {
        margin-bottom: 4px;
    }
    .nav_service_pages {
        list-style: none;

        &:first-of-type {
            margin-top: 10px;
        }
        padding-left: 10px;

        @include phone {
            padding-left: 10px;
        }
        li {
            list-style: none;
            margin: 0;
            a {
                color: #747474;
                font-size: 14px;
                text-transform: unset;
                padding-bottom: 5px;
                @include ipad {
                }
            }
        }
    }
}
.nav_menu.nav_nav_menu_active li {
    transform: translate(0);
    transition-delay: 1s;
}
.nav_menu.nav_nav_menu_unactive li {
    transform: translate(-200%);
    transition-delay: 0s !important;
}

@include ipad {
    .nav_content {
        display: unset;
        width: 62.5%;
        height: calc(100% - 60px);
        margin-top: 60px;
        left: 100%;
        padding-left: 0;
        transition: all 0.6s ease-in-out;
        @media only screen and (max-width: 500px) {
            width: 100%;
        }
    }
    .nav_content.nav_active {
        left: 100%;
        transform: translateX(-100%);

        .nav_phone_number {
            display: flex;
        }
    }
    .navigation_bar {
        width: 100%;
        height: 70px;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom: 1px solid #f5f5f6;
        transition: all 0.6s ease-in-out;
        left: unset;
        right: 0;
        &.active {
            border: unset;
            width: 62.5%;
            @media only screen and (max-width: 500px) {
                width: 100%;
            }
        }
        @include phone {
            height: 60px;
        }
    }
    .navigation_bar a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_socials {
        display: none;
    }

    .hamburger_menu {
        width: fit-content;
        position: absolute;
        top: calc(50% - 7px);
        right: 20px;
        @include phone {
            right: 10px;
        }
    }
    .nav_header_logo {
        display: none;
    }
    .nav_header {
        padding-right: 0;
        justify-content: center;
    }
    .nav_menu {
        margin-left: 0;
        width: 100%;
    }
    .nav_menu li a {
        justify-content: center;
        @include ipad {
            justify-content: flex-start;
        }
    }

    .nav_menu.nav_nav_menu_active li {
        transform: translate(0);
        transition-delay: 1s;
    }
    .nav_menu.nav_nav_menu_unactive li {
        transform: translate(+200%);
    }
}
.skip-link{
    display: none !important;
}