@import "../../styles/";
.footer {
    background-color: #000000;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
    flex-direction: column;
    margin-top: 83px;
    align-items: center;
    margin-top: auto;
    @include phone {
        padding: 0 10px;
    }
}
.footer_des_container {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    width: 45%;
}
.footer_des_title {
    font-size: 35px;
    color: #1a1a1a;
    font-weight: 600;
    word-break: break-word;
}
.footer_des {
    margin-top: 70px;
}
.footer_des p {
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.page_button {
    display: inline-block;
    float: right;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px;
    height: max-content;
    font-weight: 600;
    letter-spacing: 1px;
    background: -moz-linear-gradient(top, #39b6e3 0%, #189dd9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39b6e3', endColorstr='#189dd9',GradientType=0 );
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.2s ease-in-out;
    border: 5px solid #0078ff;
    color: #0078ff;
    cursor: pointer;
}
.page_button:hover {
    color: rgb(202, 201, 201);
    background: #0078ff;
}
.footer_bottom_links {
    display: flex;
    @include phone {
        flex-direction: column;
    }
    > a {
        @include phone {
            font-size: 13px;
            line-height: 16px;
        }
    }
}
.footer_bottom_links {
    .ftr-links {
        text-decoration: none !important;
        &:not(:last-child) {
            padding-right: 20px;
        }
    }
    @include phone {
        margin-bottom: 28px;
    }
}
.footer_bottom_links a:hover {
    color: white;
    cursor: pointer;
}
.footer_side_pdng {
    padding: 17px 0 16px 0;
    display: flex;
    width: 100%;
    max-width: 1510px;
    margin: 0 auto;
    justify-content: space-between;
    @include ipad {
        padding: 19px 0;
    }
    @include phone {
        padding: 20px 0;
        flex-direction: column-reverse;
    }
}
.footer_side_pdng {
    color: white;
}
.footer_content {
    max-width: 1510px;
    width: 100%;
    padding: 50px 0;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.39);
    justify-content: space-between;
    @include tab {
        padding: 40px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    @include phone {
        padding: 20px 0 28px;
    }
    > div:not(.socials) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-width: 380px;
        @include tab {
            justify-content: flex-end;
        }
        @include phone {
            padding-bottom: 20px;
        }
        > span {
            font-size: 15px;
            @include tab {
                white-space: nowrap;
            }
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
    > .socials {
        padding-right: 100px;
        display: flex;
        width: 100%;
        max-width: fit-content;
        justify-content: space-between;
        align-items: baseline;
        height: max-content;
        @include tab {
            padding-top: 40px;
            padding-right: 0;
            grid-column: 2/3;
        }
        @include phone {
            padding-right: 0;
            padding-top: 10px;
        }
        > a {
            padding-right: 20px;
            margin-bottom: 0;
            @include tab {
                padding-right: 30px;
            }
            img {
                width: 15px;
                height: 15px;
                overflow: unset !important;
                transition: all 0.2s;
                &:hover {
                    filter: invert(0.66) sepia(1) saturate(5) hue-rotate(180deg);
                }
            }
        }

        @include tab {
            margin: 0 auto;
        }
    }
    .mail, 
    .phone {
        a {
            &:hover {
                color: white !important;
            }
        }
    }
}
.footer_socials_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.footer_socials_container a {
    text-decoration: none !important;
    color: white !important;
    margin: 7px 0;
    font-weight: 600;
    font-size: 16px;
}
.footer_address_container {
    padding-right: 36px;
    display: flex;
}
.footer a,
.footer span {
    color: white;
    font-weight: 400;
    font-size: 16px;
    @include ipad {
        font-size: 13px !important;
    }
}
.footer a {
    @include phone {
        &:not(:last-child) {
            padding-bottom: 10px;
        }
    }
}
.footer_address_container a {
    text-decoration: unset;
    color: white;
    cursor: pointer;
}

@media only screen and (max-width: 1440px) {
    .footer_bottom_links,
    .footer_address_container {
        flex-wrap: wrap;
    }
    .footer_bottom_links {
        justify-content: flex-end;
    }
    .footer_bottom_links,
    .footer_address_container p {
        white-space: nowrap;
    }
}
@media only screen and (max-width: 991px) {
    .footer_des {
        margin-top: 35px;
    }
    .footer_des_title {
        font-size: 25px;
    }
    .footer_des_container {
        width: 100%;
        padding-right: 0;
    }
    .footer_form_container {
        width: 100%;
        flex-direction: column;
    }
    .footer_form_fields {
        min-width: unset;
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }
    .footer_form_textarea textarea {
        width: 100%;
    }
    .footer_button_container {
        width: 100%;
    }
    .footer_socials_container {
        display: none;
    }
    .footer_address_container {
        align-content: flex-start;
    }
    .footer_address_container p {
        text-align: start;
        height: 30px !important;
    }
}

@media only screen and (max-width: 768px) {
    .footer_content {
        flex-direction: column;
    }
    .footer_bottom_links {
        justify-content: flex-start;
        > a {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
        }
    }
}
