.cart_content_container {
    display: flex;
    justify-content: center;
    padding: 80px 0px;
}
.cart_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1030px;
    margin-top: 60px;

}
.cart_content_title {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 25px;
    align-items: center;
    color: #051a2e;
}
.cart_content_title div {
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
}
.cart_grid_main_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
    margin-top: 20px;
}
.cart_desktop_table {
    width: 100%;
    border-bottom: 1px solid #f5f5f6;
}

#td-mrg {
    margin: 0;
}
#td-mrg select {
    line-height: unset;
}

.cart_desktop_table tr th {
    height: 50px;
    padding-left: 15px;
    font-weight: 600;
    color: #8f8f91;
    font-size: 15px;
    border-bottom: 2px solid #f5f5f6;
}
.cart_desktop_table tr td {
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.cart_desktop_table tr td:first-child {
    max-width: 230px;
}
.cart_item_image_title_container {
    display: flex;
}
.cart_item_image {
    width: 90px;
    height: 100px;
    margin-right: 20px;
}
.cart_item_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.cart_item_title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #051a2e;
    word-break: break-word;
    max-width: 140px;
}
.cart_item_size {
    font-weight: 600;
    font-size: 15px;
    color: #051a2e;
}
.cart_item_quality .MuiFormControl-root {
    min-width: 100px;
    height: 55px;
    border: 5px solid #dadada;
    margin-right: 20px;
}
.cart_item_quality .MuiInputBase-root {
    height: 100%;
    width: 160px;
}
.cart_item_quality .MuiInput-underline:before {
    border: none;
}
.cart_item_quality .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    padding-left: 17px;
    line-height: 25px;
    color: #848484;
}
.cart_item_quality .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
}
.cart_item_quality .MuiInput-underline:after {
    border-bottom: none !important;
}
.cart_item_price {
    color: #051a2e;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.cart_item_price {
    display: flex;
    align-items: center;
    justify-content: start;
}
.cart_item_price img {
    width: 15px;
}
.x_button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20px;
    cursor: pointer;
}
.cart_pricing_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 20px 0 0 0;
    flex-direction: column;
    align-items: flex-end;
}
.empty_text{
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.empty_text .btn {
    text-decoration: unset;
    color: white;
    background-color: white;
    border: #0078ff 2px solid;
    padding: 8px 24px;
    width: 100%;
    white-space: nowrap;
    font-weight: 600;
    background-color: #0078ff;
    border: solid 4px #0078ff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s ease;
}
.empty_text .btn:hover {
    background-color: white;
    color: #0078ff;
}
.empty_text span {
    font-size: 18px;
    margin-bottom: 50px;
}
.cart_pricing_content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding-right: 15px;
}
.cart_pricing_row {
    display: flex;
    width: 235px;
    margin-bottom: 12px;
}
.cart_pricing_title {
    font-weight: 600;
    color: #051a32;
    font-size: 16px;
    width: 50%;
}
.title_bld {
    font-weight: 700;
}

.cart_pricing_value {
    font-size: 16px;
    font-weight: 600;
    color: #051a2e;
    width: 50%;
    text-align: flex-end;
    display: flex;
    justify-content: flex-end;
}
.cart_pricing_value img {
    width: 12px;
    height: 22px;
    margin-left: 2px;
}
.cart_price_blue_value {
    color: #0078ff;
}
.cart_pricing_total_price_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #f5f5f6;
    padding-top: 20px;
}
.bank_cards_section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 30px;
}
.bank_cards {
    display: flex;
    width: fit-content;
}
.bank_card {
    width: 73px;
    height: 44px;
    margin-right: 20px;
    background-color: white;
}
.bank_card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.cart_page_button {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 55px;
    border: 5px solid #0078ff;
    color: #0078ff;
    font-weight: 600;
    font-size: 16px;
    transition: 0.5s;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none !important;
}
.cart_page_button:hover {
    background-color: #0078ff;
    color: white;
}
.card_grid_mobile_container {
    display: none;
    width: 100%;
    flex-direction: column;
}
.card_mobile_item {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: row-reverse;
    border-bottom: 1px solid #f5f5f6;
    justify-content: space-between;
}
.card_mobile_item_content {
    display: flex;
    width: 100%;
}
.cart_mobile_item_image {
    width: 70px;
    height: 80px;
}
.cart_mobile_item_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.cart_mobile_des_title {
    font-size: 16px;
    font-weight: 600;
    color: #051a2e;
    margin-bottom: 12px;
    word-break: break-word;
    max-width: 204px;
}
.cart_mobile_des_size {
    font-weight: 600;
    color: #051a2e;
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}
.cart_mobile_des_quality {
    margin-bottom: 12px;
}
.cart_mobile_des_price {
    font-weight: 600;
    font-size: 16px;
    color: #051a2e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.cart_mobile_des_container {
    margin-left: 20px;
    width: 100%;
}
.cart_mobile_item_x_container {
    display: flex;
    height: max-content;
    padding-right: 10px;
    padding-top: 5px;
}

.cart_mobile_item_x_container img {
    height: fit-content;
}

@media only screen and (max-width: 1024px) {
.cart_content_title{
    padding-left: 20px;
}
}
@media only screen and (max-width: 768px) {
    .empty_text span{
        font-size: 14px;
        margin-bottom: 30px;
    }
    .empty_text{
        top: 50%;
    }
     .empty_text .btn{
        font-size: 12px;
        width: calc(100% - 20px);
        margin: 0 auto;
    }
    .cart_mobile_des_price {
        padding-top: 10px;
    }
    .wishlist-inp {
        max-width: 140px;
        height: 40px;
    }
    .card_mobile_item {
        padding: 10px;
    }
    .cart_pricing_title {
        font-size: 14px;
    }

    .card_grid_mobile_container {
        display: flex;
    }
    .cart_desktop_table {
        display: none;
    }
    .bank_cards_section {
        flex-direction: column;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .cart_page_button {
        width: 100%;
        margin-top: 30px;
    }
    .cart_pricing_content {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
    .cart_pricing_row {
        width: 100%;
    }
    .cart_content_title {
        font-size: 20px;
        padding-left: 20px;
    }
    .cart_content_title div {
        font-size: 15px;
    }
    .bank_card {
        width: 60px;
        height: 35px;
        margin-right: 10px;
    }
    .cart_content_container {
        padding: 25px 0;
    }
}

@media only screen and (max-width: 991px) {
    .cart_item_quality .MuiInputBase-root {
        width: unset;
    }
    .cart_item_quality .MuiFormControl-root {
        height: 40px;
    }
}

@media only screen and (max-width: 425px) {
    .cart_mobile_item_image img {
        width: 60px;
        height: 67px;
    }
}
