.collection_header{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.collection_header_title{
    font-weight: 600;
    font-size: 18px;
    color: white;
    text-align: center;
    text-transform: uppercase;
}
@media only screen and (max-width: 991px) {
    .collection_header{
        height: 150px;
    }
    .collection_header_title{
        font-size: 16px;
    }
}