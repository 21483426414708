.payment_header{
    display: flex;
    width: 100%;
}
.payment_header_top_side{
    display: flex;
    width: 100%;
    height: 337px;
    background-color: #0079ff;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
}
.payment_header_top_side img{
    margin-top: 50px;
}
.payment_header_title{
    font-size: 25px;
    font-weight: 600;
    color: white;
    margin-top: 60px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.payment_header_order_section{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 728px;
    background-color: white;

}
.payment_header_order{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -100px;
}
.payment_header_order_section_title{
    display: flex;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #f5f5f6;
    font-weight: 600;
    color: #8f8f8f;
    font-size: 15px;
}
.payment_order_section_pricing{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
}
.payment_order_pricing_row{
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: space-between;
}
.payment_order_pricing_title{
    font-weight: 600;
    font-size: 16px;
    color: #051a2e;
    word-break: break-word;
}

.payment_order_pricing_value{
   font-weight: 600;
    font-size: 16px;
    color: #051a2e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment_order_pricing_value img {
    width: 15px;
}
.payment_header_order_total_coast{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 25px 20px;

}
.payment_total_coast_title{
    font-weight: 600;
    font-size: 16px;
    color: #051a2e;
}
.payment_total_coast_value{
    font-weight: 600;
    font-size: 16px;
    color: #051a2e;
}
.blue_color{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0078ff !important;
}
.blue_color img {
    width: 15px;
}
@media only screen and (max-width: 991px) {
    .payment_order_pricing_title{
    max-width: 200px;
    }
    .payment_header_top_side{
        margin-top: 60px;
        height: 150px;
    }
    .payment_header_order{
        margin-top: 0;
    }
    .payment_header_top_side img{
        margin-top: 30px;
    }
    .payment_header_title{
        margin-top: 15px;
        font-size: 20px;
    }
}

@media only screen and (max-width:475px){
    .payment_order_pricing_value img, .blue_color  img{
        width: 13px;

    }
    .payment_order_section_pricing,.payment_header_order_total_coast{
        padding: 16px 20px;
    }
    .payment_order_pricing_title,.payment_order_pricing_value, .payment_total_coast_title, .payment_total_coast_value{
        font-size: 14px;
    }
}