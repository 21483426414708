.delviery-title {
    display: flex;
    justify-content: center;
    font-size: 40px;
    background-color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    font-weight: 600;
    padding: 8px 20px 8px 30px;
    font-weight: 500;
}

.payment-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1550px;
    padding: 0 20px;
    margin: 165px auto 215px;
}

.payment-text-contnet {
    max-width: 735px;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    width: 100%;
    color: #636363;
}

.payment-text-title h2 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    margin-bottom: 15px;
    word-break: break-word;
    color: #000000;
}

@media only screen and (max-width: 1024px) {
    .payment-flex {
        margin: 130px auto 60px;
        padding: 0 20px;
    }
    .delviery-title {
        margin: 100px auto 40px;
    }
}

@media only screen and (max-width: 768px) {
    .payment-text-title h2 {
        font-size: 22px;
        line-height: 27px;
    }

    .payment-flex {
        margin: 100px auto 30px;
        padding: 0 10px;
    }
}
