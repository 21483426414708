@import "../../../styles/";

.input-wrapp {
    &.product {
        .input-box {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            @include phone {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
            }
        }
        .check-button {
            display: flex;
            flex: 1 0 100%;
            align-items: center;
            justify-content: center;
            height: 50px;
            border: 2px solid #EAEAEA;
            font-size: 13px;
        font-weight: 600;
        line-height: 16px;
            color: #636363;
            transition: all 0.3s;
            cursor: pointer;
            @include phone {
                height: 40px;
            }
            &.active {
                color: #ffffff;
                border-color: #0078FF;
                background: #0078FF;
            }
            @media (hover: hover) {
                &:hover {
                    border-color: #0078FF;
                }
            }
        }
    }
}