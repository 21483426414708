* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: #ffffff !important;
}

@font-face {
    font-family: Montserrat;
    src: url("../../fonts/MONTSERRAT-REGULAR.TTF");
    font-weight: 400;
}

@font-face {
    font-family: Montserrat;
    src: url("../../fonts/MONTSERRAT-SEMIBOLD.TTF");
    font-weight: 600;
}
@font-face {
    font-family: Montserrat;
    src: url("../../fonts/MONTSERRAT-BOLD.TTF");
    font-weight: bold;
}

@font-face {
    font-family: MontserratArm;
    src: url("../../fonts/MONTSERRATARM-REGULAR.OTF");
    font-weight: 400;

}

@font-face {
    font-family: MontserratArm;
    src: url("../../fonts/MONTSERRATARM-SEMIBOLD.OTF");
    font-weight: 600;

}
@font-face {
    font-family: MontserratArm;
    src: url("../../fonts/MONTSERRATARM-BOLD.OTF");
    font-weight: bold;

}
