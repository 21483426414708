@import "../../../styles/";
.main-slider-section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @include tab {
        max-height: 650px;
    }
    @include phone {
        max-height: 380px;
    }
    .slick-slider {
        height: 100%;
        width: 100%;
        .slick-track {
            flex-direction: column !important;
            @include ipad {
                flex-direction: row !important;
                display: flex;
            }
        }
        .slick-list {
            height: 100%;
            overflow: inherit !important;
            max-height: unset !important;
        }
        .slick-dots {
            position: absolute;
            top: 50%;
            left: 32px;
            transform: translateY(-50%);
            width: max-content;
            height: fit-content;
            @media screen and (max-width: 1510px) {
                left: 15px;
            }
            li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 26px;
                height: 26px;
                margin: 5px;
                border-color: #ffffff;
                border-radius: 50%;
                &.slick-active {
                    border: 1px solid #ffffff;
                    width: 28px;
                    height: 27px;
                    > button {
                        &::before {
                            background-color: white;
                        }
                    }
                }
                button {
                    position: relative;
                    font-size: 0;
                    padding: 10px;
                    border: none;
                    background-color: transparent;
                    outline: 0 !important;
                    width: 26px;
                    height: 26px;
                    cursor: pointer;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background-color: rgba(255, 255, 255, 0.3);
                        transition: all 0.3s;
                    }
                }
            }
        }
    }
    .container_wrapp {
        width: 100%;
        height: 100vh;
        display: flex !important;
        align-items: flex-end;
        @include tab {
            max-height: 650px;
        }
        @include phone {
            padding: 0;
            max-height: 380px;
        }
    }
    .slick-active {
        .text-wrapp {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 54px;
            color: #ffffff;
            width: 100%;
            padding: 0 50px;
            text-align: center;
            margin: 0 auto;
            z-index: 2;
            @include ipad {
                font-size: 32px;
            }
            span {
                opacity: 0;
                animation: revolveScale 0.1s forwards;
            }
        }
    }
    .slider-wrapper {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        left: -1px;
        top: -1px;
    }
    .main-side {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:not(:first-child) {
                display: none;
            }
            @include phone {
                &:first-child {
                    display: none;
                }
                &:not(:first-child) {
                    display: unset;
                }
            }
        }
    }
}
@keyframes revolveScale {
    to {
        opacity: 1;
    }
}
