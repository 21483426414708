@import "../../styles/";

.personal_page {
    width: 100%;
    height: 100%;
    .main_image {
        width: 100%;
        height: 100vh;
        position: relative;
        @include ipad {
            max-height: 500px;
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        > h3 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%);
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            @include phone {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    > .person_info {
        background: #ffffff;
        box-shadow: 0px 3px 15px rgba(3, 23, 47, 0.1);
        width: 100%;
        > .content {
            max-width: 1550px;
            width: 100%;
            margin: 0 auto;
            padding: 20px 20px 32px;
            @include phone {
                padding: 20px;
            }
            > h2 {
                padding-bottom: 15px;
                font-weight: 600;
                font-size: 30px;
                line-height: 37px;
                color: #000000;
                margin: 0;
                @include phone {
                    font-size: 22px;
                    line-height: 27px;
                }
            }
            > span {
                &:not(:last-child) {
                    margin-right: 30px;
                }
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: #000000;
                > span {
                    color: #0078ff;
                }
            }
        }
    }
    .page_container {
        .page_content {
            .portfolio_page {
                margin-top: 30px;
                @include phone {
                    margin-top: 20px;
                }
            }
            .page_title {
                display: none;
            }
        }
    }
}
