@import "../styles";
$blue: #0078ff;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: Montserrat !important;
}
.arm_font {
    font-family: MontserratArm !important;
}

.page_title {
    margin: 0;
    @extend %font30;
}
textarea {
    font-family: Montserrat !important;

    &::-webkit-input-placeholder {
        font-weight: 600;
    }
}
input,
select,
textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
button,
a {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    transition: 0.3s linear;
    outline: 0 !important;
    &:hover {
        text-decoration: unset !important;
    }
}
.main-btn {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    border: 4px solid #0078ff;
    padding: 13px 24px;
    background: #0078ff;
    text-transform: uppercase;
    color: #fff;
    margin-right: 40px;
    outline: 0 !important;
    @include phone {
        margin: 0;
        padding: 10px 20px 9px;
        font-size: 11px;
        font-weight: 600;
        line-height: 13px;
    }
    &:hover {
        background: #fff;
        color: #0078ff;
        text-decoration: none;
    }
    &.primary {
        background: #fff;
        color: #0078ff;
        border: 4px solid #0078ff;
        &:hover {
            color: #fff;
            background: #0078ff;
        }
    }
}

.grid_items {
    display: grid;
    grid-gap: 30px;
    grid-auto-rows: 14.6vw;
    grid-template-columns: repeat(4, 1fr);
    @include tab {
        grid-auto-rows: 35.81vw;
        grid-template-columns: 1fr 1fr;
    }
    @include phone {
        grid-auto-rows: 75vw;
        grid-template-columns: 1fr;
    }
    > div,
    a {
        display: flex;
        flex-direction: column;
        text-decoration: unset;
        color: inherit;
        .image-box {
            display: flex;
            width: 100%;
            height: calc(100% - 30px);
            overflow: hidden;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease-in-out;
            }
        }
        > span {
            @include lineClamp(1);
            margin-top: 10px;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: black;
        }
        &:nth-child(15n-13),
        &:nth-child(15n-4),
        &:nth-child(15n-5) {
            grid-column: span 2;
            grid-row: span 2;
            @include ipad {
                grid-column: unset;
                grid-row: unset;
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.buttons_tab {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    > button {
        font-family: Montserrat !important;
        margin-right: 10px;
        height: 40px;
        padding: 0 27px;
        border: 2px solid transparent;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        text-transform: capitalize;
        transition: border-color 0.3s linear;
        &:hover {
            border-color: $blue;
            color: $blue;
        }
        &.active {
            color: white;
            background-color: $blue;
            border-color: $blue;
        }
    }
}
.page__container {
    margin: 165px auto 100px;
    max-width: 1550px;
    width: 100%;
    padding: 0 20px;
    @include tab {
        margin: 120px auto 60px;
    }
    @include phone {
        margin: 100px auto 40px;
        padding: 0 10px;
    }
    > div {
        max-width: 750px;
        width: 100%;
        margin-right: auto;
        > .title {
            margin-bottom: 30px;
            h1 {
                color: #000000;
                margin-bottom: 15px;
                font-size: 30px;
                font-weight: 600;
                line-height: 37px;
                @include phone {
                    margin-bottom: 10px;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 27px;
                }
            }
            span {
                color: #636363;
                font-size: 14px;
                font-weight: 600;
                line-height: 25px;
            }
        }
    }
    .subtitle {
        color: #000000;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        white-space: break-space;
    }
}
.load_more {
    cursor: pointer;
    margin: 30px auto 0;
    border: solid 4px #0078ff;
    width: max-content;
    padding: 13px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    text-transform: uppercase;
    color: #0078ff;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    @include tab {
        margin: 30px auto 0;
    }
    @include phone {
        margin: 30px auto 0;
        padding: 10px 12px;
        font-size: 11px;
        font-weight: 600;
        line-height: 13px;
    }
    &:hover {
        background-color: #0078ff;
        color: white;
    }
}
.body-fix {
    overflow: hidden;
    .page_container {
        &::after {
            content: "";
            position: absolute;
            transition: 0.5s;
            z-index: 100;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

@include ipad {
    body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }
    html {
        height: -webkit-fill-available;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
