@import "../../../styles/";

@media only screen and (max-width: 320px) {
    .labels-container {
        top: 18px;
        right: 18px;
    }
}

.shop_layout {
    width: 100%;
    margin: 0 auto 100px;
    max-width: 1510px;

    @include tab {
        margin: 0 auto 60px;
    }
    @include phone {
        margin: 0 auto 40px;
    }

    > .about_content {
        margin: 100px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include ipad {
            margin: 60px auto 38px;
            flex-direction: column;
            align-items: flex-start;
        }
        @include phone {
            margin: 40px auto 20px;
        }
        > .left_side {
            display: flex;
            flex-direction: column;
            max-width: 735px;
            width: 100%;
            margin-right: 35px;
            @include tab {
                margin: 0 0 30px;
            }
            @include phone {
                margin: 0 0 20px;
            }
            > .title {
                text-transform: capitalize;
                margin-bottom: 15px;
                > h3 {
                    margin: 0;
                    color: #000000;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 37px;
                }
            }
            > .description {
                display: flex;
                flex-direction: column;
                p {
                    margin: 0;
                }
                > span {
                    color: #636363;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 25px;
                    margin-bottom: 20px;
                    @include phone {
                        margin-bottom: 15px;
                    }
                }
                > a {
                    color: #0078ff;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16px;
                    position: relative;
                    width: max-content;
                    text-decoration: unset;
                    text-transform: uppercase;
                    > svg {
                        transition: 0.4s;
                        position: absolute;
                        right: -25px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        svg {
                            right: -30px;
                        }
                    }
                }
            }
        }
        > .right_side {
            width: 100%;
            justify-content: space-between;
            display: flex;
            @include phone {
                flex-direction: column;
            }
            > .box {
                cursor: pointer;
                text-decoration: none;
                max-width: 355px;
                width: 100%;
                background-color: #0078ff;
                padding: 86px 0 36px 36px;
                color: #ffffff;
                border: solid 4px #0078ff;
                &:not(:last-child) {
                    margin-right: 30px;
                }
                @include ipad {
                    margin-right: 0;
                    max-width: 349px;
                }
                @include phone {
                    max-width: unset;
                    padding: 68px 0 20px 30px;
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
                > span {
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
                > h3 {
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 37px;
                    margin: 0;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &:hover {
                    transition: all 0.4s;
                    background-color: #ffffff;
                    color: #0078ff;
                }
            }
        }
    }
}
